import App from "@/App.vue";
import "@/assets/styles/global.scss";
import router from "@/router";
import "bootstrap-icons/font/bootstrap-icons.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import VueCookies from "vue-cookies";
import VueDragscroll from "vue-dragscroll";
import VueFullscreen from "vue-fullscreen";
import { MathfieldElement } from "mathlive";
import VueMathjax from "vue-mathjax-next";

window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

const piniaInstance = createPinia();
piniaInstance.use(piniaPluginPersistedstate);

const mfe = new MathfieldElement();

const app = createApp(App);
app
  .use(VueDragscroll)
  .use(piniaInstance)
  .use(VueCookies, { expires: "7d" })
  .use(router)
  .use(ElementPlus)
  .use(VueMathjax)
  .use(VueFullscreen);

app.mount("#app");

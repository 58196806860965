import { handleApiError, handleApiResponse } from "@/methods/api";
import { useSessionStore } from "@/stores/sessionStore";
import {
  AddBulkUsers,
  AddBulkUsersResponse,
  AddEditAffiliation,
  AddEditBatch,
  AddEditUser,
  AffiliationActivityCounts,
  AffiliationDisplay,
  AffiliationUserCounts,
  BatchDisplay,
  StudentUsageCounts,
  TeacherUsageCounts,
  UserDisplay,
} from "@/types/admin";
import {
  BatchEffortAnalysisData,
  BatchPerformanceData,
  SyllabusBatchWiseData,
  SyllabusTeacherWiseData,
} from "@/types/affiliationReport";
import {
  AffiliationAssignmentReport,
  AffiliationAssignmentReportDetails,
  AssignmentData,
  AssignmentDesign,
  AssignmentStudent,
  PreloadedAssignmentInfo,
} from "@/types/assignment";
import {
  AssignmentEvent,
  AssignmentTimeStamps,
  ConductAssignmentInfo,
  ConductAssignmentState,
  ConductAssignmentStore,
} from "@/types/conductAssignment";
import {
  ConductTestInfo,
  ConductTestState,
  ConductTestStore,
  TestEvent,
  TestTimeStamps,
} from "@/types/conductTest";
import {
  ContentChildrenInputs,
  ContentConfig,
  ContentData,
  ContentIds,
  QuestionBankQuestion,
  QuestionDetails,
  QuestionDetailsUpdate,
  QuestionPdfInfo,
  QuestionPdfPage,
  QuestionPdfSettings,
  QuestionSolutionImage,
} from "@/types/content";
import {
  AffiliationCourseBatchTree,
  ApiResponse,
  IdNameChildren,
  IdNamePair,
  NavbarMenu,
  UserAlert,
} from "@/types/global";
import {
  AffiliationTestReport,
  AffiliationTestReportDetails,
  InstituteTestData,
  InstituteTestDesign,
  InstituteTestStudent,
  InstituteTestTemplate,
  ManualMarksData,
  PreloadedTestInfo,
} from "@/types/instituteTest";
import {
  CreateLiveClassInputs,
  LiveClassData,
  LiveClassLogsDisplay,
  LiveClassLogsInputs,
} from "@/types/liveClass";
import { CreateLivePracticeInputs, LivePracticeData } from "@/types/livePractice";
import {
  OmrProcessingResponse,
  OmrReaderConfig,
  OmrResult,
  OmrTemplate,
  QuestionPaperTemplate,
} from "@/types/offlineTest";
import { Section, SectionGroup, SelectorQuestion } from "@/types/questionSelector";
import { SelfTestCreate, SelfTestDisplay, SelfTestTemplate } from "@/types/selfTest";
import { AssignmentReport, AssignmentReportDetails } from "@/types/studentAssignmentReport";
import { PerformanceTrendsData, SubjectCoverageData } from "@/types/studentReport";
import { TestReport, TestReportDetails } from "@/types/studentTestReport";
import {
  AddEditLecturePlan,
  BatchLecturePlan,
  BatchTeachingAssessments,
  LecturePlan,
  SubjectTeachingVideos,
  TeachingAid,
} from "@/types/teachingContent";
import axios, { AxiosInstance } from "axios";

export default class ApiVerified {
  private axiosInstance: AxiosInstance;

  constructor(accessToken: string) {
    this.axiosInstance = axios.create({ baseURL: import.meta.env.VITE_API_URL });
    this.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const sessionStore = useSessionStore();
        if (error.response.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
          const newAccessToken = await sessionStore.refreshAccessToken();
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        }
        return Promise.reject(error);
      },
    );
  }

  async getNavbarMenu(userRoleId: number, affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<NavbarMenu>(
        `app-settings/get-web-navbar-menu`,
        {
          params: { userRoleId: userRoleId, affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUserAlerts(userRoleId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<UserAlert>>(
        `app-settings/get-user-alerts`,
        { params: { userRoleId: userRoleId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async changePassword(
    userId: number,
    currentPassword: string,
    newPassword: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/change-password`, {
        userId: userId,
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async resetUserPassword(
    userId: number,
    newPassword: string,
    setToDefault: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/reset-user-password`, {
        userId: userId,
        newPassword: newPassword,
        setToDefault: setToDefault,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfTestTemplates(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SelfTestTemplate[]>(
        `self-test/get-test-templates`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createSelfTest(testData: SelfTestCreate): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<number>(`self-test/create-test`, testData);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loadTest(testId: number, testType: string, userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<ConductTestStore>(`conduct-test/load-test`, {
        params: { testId: testId, testType: testType, userId: userId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startTest(
    testInstanceId: number,
    testType: string,
    duration: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<TestTimeStamps>(`conduct-test/start-test`, {
        testInstanceId: testInstanceId,
        testType: testType,
        duration: duration,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async pushUnsavedTestEvents(
    testInstanceId: number,
    testType: string,
    testEvents: Array<TestEvent>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`conduct-test/push-test-events`, {
        testInstanceId: testInstanceId,
        testType: testType,
        testEvents: testEvents,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endTest(testInfo: ConductTestInfo, testState: ConductTestState): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`conduct-test/end-test`, {
        TestInfo: testInfo,
        TestState: testState,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentTestReport(
    testInstanceId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TestReport>(
        `conduct-test/student-test-report`,
        {
          params: {
            testInstanceId: testInstanceId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentTestReportDetails(
    testInstanceId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TestReportDetails>(
        `conduct-test/student-test-report-details`,
        {
          params: {
            testInstanceId: testInstanceId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfTestsForStudent(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SelfTestDisplay>(
        `self-test/get-tests-for-student`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationTestReport(
    testId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationTestReport>(
        `conduct-test/affiliation-test-report`,
        {
          params: {
            testId: testId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationTestReportDetails(
    testId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationTestReportDetails>(
        `conduct-test/affiliation-test-report-details`,
        {
          params: {
            testId: testId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async resetStudentTestAttempts(
    testId: number,
    testType: string,
    studentId: number,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(`conduct-test/reset-student-test`, {
        params: {
          testId: testId,
          testType: testType,
          studentId: studentId,
          affiliationId: affiliationId,
          userId: userId,
          userRole: userRole,
        },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async resetAllTestAttempts(
    testId: number,
    testType: string,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(
        `conduct-test/reset-all-test-attempts`,
        {
          params: {
            testId: testId,
            testType: testType,
            affiliationId: affiliationId,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAllCourseChapters(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `content/get-all-course-chapters`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSubjectsForBatch(batchId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-subjects-for-batch`,
        {
          params: { batchId: batchId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseChaptersForBatch(
    batchId: number,
    subjectId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-chapters-for-batch`,
        {
          params: { batchId: batchId, subjectId: subjectId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getContentNames(contentIds: number[], contentType: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<IdNamePair[]>(`content/content-names`, {
        contentIds: contentIds,
        contentType: contentType,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getContentChildren(contentChildrenInputs: ContentChildrenInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ContentData[]>(
        `content/content-children`,
        contentChildrenInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getNestedContentChildren(
    contentChildrenInputs: ContentChildrenInputs,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ContentData[]>(
        `content/nested-content-children`,
        contentChildrenInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelectedContent(
    courseChapterIds: Array<number>,
    kscClusterIds: Array<number> | null,
    contentConfig: Array<ContentConfig>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ContentIds>(`content/selected-content`, {
        courseChapterIds: courseChapterIds,
        kscClusterIds: kscClusterIds,
        contentConfig: contentConfig,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getKSCClustersForCourseChapter(
    courseChapterId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `content/get-ksc-clusters-for-course-chapter`,
        {
          params: { courseChapterId: courseChapterId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getKSCsForKSCCluster(kscClusterId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `content/get-kscs-for-ksc-cluster`,
        {
          params: { kscClusterId: kscClusterId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getNestedKSCsForChapter(
    courseChapterId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNameChildren[]>(
        `content/nested-kscs-for-chapter`,
        {
          params: { courseChapterId: courseChapterId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAvailableTestQuestionsForChapter(
    courseChapterId: number,
    questionTypeId: number,
    onlyActive: boolean,
    exclusionBatchIdsCsv: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<SelectorQuestion[]>(
        `institute-test/get-available-questions-for-chapter`,
        {
          courseChapterId: courseChapterId,
          questionTypeId: questionTypeId,
          onlyActive: onlyActive,
          exclusionBatchIdsCsv: exclusionBatchIdsCsv,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestRecommendedQuestions(
    testSection: Section,
    exclusionBatchIds: Array<number> | null,
    difficultyLevelId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<SelectorQuestion[]>(
        `institute-test/get-recommended-questions`,
        {
          testSection: testSection,
          exclusionBatchIds: exclusionBatchIds,
          difficultyLevelId: difficultyLevelId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getChildAffiliations(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-child-affiliations`,
        {
          params: { affiliationId: affiliationId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCoursesForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-affiliation-courses`,
        {
          params: { affiliationId: affiliationId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffliationBatchTree(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationCourseBatchTree[]>(
        `affiliation-batch/get-course-batch-tree`,
        {
          params: { affiliationId: affiliationId, onlyActive: true, includeChildren: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLiveClassFacultyDetails(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`affiliation-batch/get-faculty`, {
        params: { affiliationId: affiliationId, onlyActive: true },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticeModeratorDetails(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-moderators`,
        {
          params: { affiliationId: affiliationId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCommonSubjectsForBatches(batchIdsCsv: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-common-subjects`,
        {
          params: { batchIdsCsv: batchIdsCsv, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentsForLivePractice(batchIdsCsv: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `assignment/get-assignments-for-live-practice`,
        {
          params: { batchIdsCsv: batchIdsCsv },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCommonChaptersForBatches(batchIdsCsv: string, subjectId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-common-chapters`,
        {
          params: { batchIdsCsv: batchIdsCsv, subjectId: subjectId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionTypesForContent(
    selectedContent: ContentIds,
    categoryId: number | null,
    minimumCount: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<number>>(
        `content/get-question-types-for-content`,
        { selectedContent: selectedContent, categoryId: categoryId, minimumCount: minimumCount },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionHtmlUrl(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-html`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionDisplayUrl(
    questionId: number,
    displayType: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-html`, {
        params: { questionId: questionId, displayType: displayType },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionImageUrl(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-image`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSolutionImageUrl(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-solution-image`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionConceptImages(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string[]>(
        `content/get-question-concept-images`,
        {
          params: { questionId: questionId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionSolutionImage(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-solution-image`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionDetailsByCode(questionCode: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<QuestionDetails>(
        `content/get-question-details-by-code`,
        {
          params: { questionCode: questionCode },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLiveClassesForAffliation(affiliationId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LiveClassData[]>(
        `live-class/get-live-classes-for-affiliation`,
        {
          params: { affiliationId: affiliationId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticesForAffliation(
    affiliationId: number,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LivePracticeData[]>(
        `live-practice/get-live-practices-for-affiliation`,
        {
          params: { affiliationId: affiliationId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLiveClassesForFaculty(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LiveClassData[]>(
        `live-class/get-live-classes-for-faculty`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticesForModerator(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LivePracticeData[]>(
        `live-practice/get-live-practices-for-moderator`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLiveClassesForStudent(
    userId: number,
    userName: string,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LiveClassData[]>(
        `live-class/get-live-classes-for-student`,
        {
          params: { userId: userId, userName: userName, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticesForStudent(
    userId: number,
    userName: string,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LivePracticeData[]>(
        `live-practice/get-live-practices-for-student`,
        {
          params: { userId: userId, userName: userName, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async checkZoomAccess(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `affiliation-batch/check-zoom-access`,
        {
          params: { affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createLiveClass(liveClassInputs: CreateLiveClassInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-class/create-live-class`,
        liveClassInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createLivePractice(liveClassInputs: CreateLivePracticeInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/create-live-practice`,
        liveClassInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleLiveClass(
    liveClassId: number,
    startTime: string,
    duration: number,
    includeBasicZoom: boolean,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/reschedule-live-class`, {
        liveClassId: liveClassId,
        startTime: startTime,
        duration: duration,
        includeBasicZoom: includeBasicZoom,
        allFutureEvents: allFutureEvents,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleLivePractice(
    livePracticeId: number,
    startTime: string,
    duration: number,
    includeBasicZoom: boolean,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/reschedule-live-practice`,
        {
          livePracticeId: livePracticeId,
          startTime: startTime,
          duration: duration,
          includeBasicZoom: includeBasicZoom,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async modifyLiveClassSubject(
    liveClassId: number,
    subjectId: number,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ApiResponse>(
        `live-class/modify-live-class-subject`,
        {
          liveClassId: liveClassId,
          subjectId: subjectId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async modifyLiveClassFaculty(
    liveClassId: number,
    facultyId: number,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-class/modify-live-class-faculty`,
        {
          liveClassId: liveClassId,
          facultyId: facultyId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async modifyLivePracticeModerator(
    livePracticeId: number,
    moderatorId: number,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/modify-live-practice-moderator`,
        {
          livePracticeId: livePracticeId,
          moderatorId: moderatorId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startLiveClass(liveClassId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/start-live-class`, {
        liveClassId: liveClassId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startLivePractice(livePracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-practice/start-live-practice`, {
        livePracticeId: livePracticeId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endLiveClass(liveClassId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/end-live-class`, {
        liveClassId: liveClassId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endLivePractice(livePracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-practice/end-live-practice`, {
        livePracticeId: livePracticeId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addClassLogs(classLogsInputs: LiveClassLogsInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-class/add-class-logs`,
        classLogsInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getClassLogs(liveClassId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LiveClassLogsDisplay>(
        `live-class/get-class-logs`,
        {
          params: { liveClassId: liveClassId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cancelLiveClass(liveClassId: number, allFutureEvents: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/cancel-live-class`, {
        liveClassId: liveClassId,
        allFutureEvents: allFutureEvents,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cancelLivePractice(livePracticeId: number, allFutureEvents: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/cancel-live-practice`,
        {
          livePracticeId: livePracticeId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLecturePlanForCourse(affiliationId: number, courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<BatchLecturePlan[]>(
        `teaching-content/get-lecture-plan-for-course`,
        {
          params: { affiliationId: affiliationId, courseId: courseId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLecturePlanForChapter(batchId: number, courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LecturePlan[]>(
        `teaching-content/get-lecture-plan-for-chapter`,
        {
          params: { batchId: batchId, courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingLecturesForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `teaching-content/get-teaching-lectures-for-chapter`,
        {
          params: { courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingVideosForCourse(affiliationId: number, courseId: number) {
    try {
      const response = await this.axiosInstance.get<SubjectTeachingVideos[]>(
        `teaching-content/get-teaching-videos-for-course`,
        {
          params: { affiliationId: affiliationId, courseId: courseId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingAssessmentsForCourse(affiliationId: number, courseId: number) {
    try {
      const response = await this.axiosInstance.get<BatchTeachingAssessments[]>(
        `teaching-content/get-teaching-assessments-for-course`,
        {
          params: { affiliationId: affiliationId, courseId: courseId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateLectureProgress(
    teachingLectureId: number,
    batchId: number,
    lectureStatusId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-lecture-progress`,
        {
          teachingLectureId: teachingLectureId,
          batchId: batchId,
          lectureStatusId: lectureStatusId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingLectureClicks(
    teachingLectureId: number,
    userId: number,
    pdfClickTypeId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-lecture-clicks`,
        {
          teachingLectureId: teachingLectureId,
          userId: userId,
          pdfClickTypeId: pdfClickTypeId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingAidForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TeachingAid>(
        `teaching-content/get-teaching-aid-for-chapter`,
        {
          params: { courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveTeachingAidForChapter(
    courseChapterId: number,
    teachingAidData: TeachingAid,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/save-teaching-aid-for-chapter`,
        {
          courseChapterId: courseChapterId,
          teachingAidData: teachingAidData,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveLecturePlanForChapter(
    courseChapterId: number,
    lecturePresentationPdfUrl: string,
    allLectures: Array<AddEditLecturePlan>,
    deletedLectures: Array<number> | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/save-lecture-plan-for-chapter`,
        {
          courseChapterId: courseChapterId,
          lecturePresentationPdfUrl: lecturePresentationPdfUrl,
          allLectures: allLectures,
          deletedLectures: deletedLectures,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingAssignment(
    teachingAssignmentId: number,
    assignmentName: string,
    preloadedAssignmentId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-teaching-assignment`,
        {
          teachingAssignmentId: teachingAssignmentId,
          assignmentName: assignmentName,
          preloadedAssignmentId: preloadedAssignmentId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteTeachingAssignment(teachingAssignmentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/delete-teaching-assignment`,
        { teachingAssignmentId: teachingAssignmentId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingLectureName(
    teachingLectureId: number,
    lectureName: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-lecture-name`,
        { teachingLectureId: teachingLectureId, lectureName: lectureName },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingAssessment(
    teachingAssessmentId: number,
    assessmentName: string,
    preloadedTestId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-teaching-assessment`,
        {
          teachingAssessmentId: teachingAssessmentId,
          assessmentName: assessmentName,
          preloadedTestId: preloadedTestId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteTeachingAssessment(teachingAssessmentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/delete-teaching-assessment`,
        { teachingAssessmentId: teachingAssessmentId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateSessionLastAccessed(loginSessionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-session-last-accessed`, {
        loginSessionId: loginSessionId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async submitDeleteAccountPermanently(userId: number, loginId: string, password: string) {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/delete-account-permanently`, {
        userId: userId,
        loginId: loginId,
        password: password,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestsForBatch(batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `institute-test/get-institute-tests-for-batch`,
        {
          params: { batchId: batchId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTestManualMarksData(instituteTestId: number, batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<ManualMarksData>(
        `institute-test/get-test-manual-marks-data`,
        {
          params: { instituteTestId: instituteTestId, batchId: batchId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveTestManualMarksData(manualMarksData: ManualMarksData): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-test-manual-marks-data`,
        { manualMarksData: manualMarksData },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestsForAffiliation(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PreloadedTestInfo[]>(
        `institute-test/preloaded-tests-for-affiliation`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestsForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `institute-test/preloaded-tests-for-chapter`,
        { params: { courseChapterId: courseChapterId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishPreloadedTest(
    testId: number,
    affiliationId: number,
    testName: string,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
    teachingAssessmentId: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/publish-preloaded-test`,
        {
          testId: testId,
          affiliationId: affiliationId,
          testName: testName,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
          teachingAssessmentId: teachingAssessmentId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestsForStudent(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PreloadedTestInfo[]>(
        `institute-test/preloaded-tests-for-student`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStandardTestTemplatesForCourse(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestTemplate[]>(
        `institute-test/standard-test-templates-for-course`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSavedTestTemplatesForCourse(
    courseId: number,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestTemplate[]>(
        `institute-test/saved-test-templates-for-course`,
        { params: { courseId: courseId, affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveInstituteTestTemplate(
    instituteTestTemplate: InstituteTestTemplate,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`institute-test/save-test-template`, {
        testTemplate: instituteTestTemplate,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestDesign(
    instituteTestId: number,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestDesign>(
        `institute-test/get-test-design`,
        {
          params: { instituteTestId: instituteTestId, affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestInstructions(instituteTestId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestDesign>(
        `institute-test/get-test-instructions`,
        { params: { instituteTestId: instituteTestId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveInstituteTestInstructions(
    instituteTestId: number,
    affiliationId: number,
    testInstructions: Array<string>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-test-instructions`,
        {
          instituteTestId: instituteTestId,
          affiliationId: affiliationId,
          testInstructions: testInstructions,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestsForAffliation(
    affiliationId: number,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestData>(
        `institute-test/get-tests-for-affiliation`,
        {
          params: { affiliationId: affiliationId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestsForStudent(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestStudent>(
        `institute-test/get-tests-for-student`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTestAnswerKeyPdf(instituteTestId: number, affiliationId: number) {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/get-test-answer-key-pdf`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTestSolutionsPdf(instituteTestId: number, affiliationId: number) {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/get-test-solutions-pdf`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionPaperTemplates(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionPaperTemplate>>(
        `institute-test/get-question-paper-templates`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateTestQuestionPdf(
    instituteTestId: number,
    affiliationId: number,
    templateName: string,
    groupQuestionTypes: boolean,
    forceRegenerate: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/generate-test-question-pdf`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
            templateName: templateName,
            groupQuestionTypes: groupQuestionTypes,
            forceRegenerate: forceRegenerate,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOmrTemplatesForTest(instituteTestId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<OmrTemplate>>(
        `institute-test/get-test-omr-templates`,
        { params: { instituteTestId: instituteTestId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateTestOmrImage(
    instituteTestId: number,
    batchId: number,
    templateName: string | null,
    groupQuestionTypes: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/generate-test-omr-image`,
        {
          params: {
            instituteTestId: instituteTestId,
            batchId: batchId,
            templateName: templateName,
            groupQuestionTypes: groupQuestionTypes,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async submitOmrsForProcessing(
    instituteTestId: number,
    batchId: number,
    templateName: string,
    omrFile: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<OmrProcessingResponse>(
        `institute-test/submit-omrs-for-processing`,
        {
          instituteTestId: instituteTestId,
          batchId: batchId,
          templateName: templateName,
          omrFile: omrFile,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOmrResults(instituteTestId: number, batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get(`institute-test/get-omr-results`, {
        params: { instituteTestId: instituteTestId, batchId: batchId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOmrReaderConfig(instituteTestId: number, batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<OmrReaderConfig>(
        `institute-test/get-omr-reader-config`,
        {
          params: { instituteTestId: instituteTestId, batchId: batchId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveOmrResults(omrResult: Array<OmrResult>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-reviewed-omrs`,
        omrResult,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteInstituteTestTemplate(
    mongoId: string,
    courseId: number,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(
        `institute-test/delete-test-template`,
        { params: { mongoId: mongoId, courseId: courseId, affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createInstituteTest(
    testDesign: InstituteTestDesign,
    affiliationId: number,
    courseId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/create-institute-test`,
        {
          testDesign: testDesign,
          affiliationId: affiliationId,
          courseId: courseId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveTestQuestions(
    instituteTestId: number,
    affiliationId: number,
    sectionGroups: Array<SectionGroup>,
    finalizeTest: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-test-questions`,
        {
          instituteTestId: instituteTestId,
          affiliationId: affiliationId,
          sectionGroups: sectionGroups,
          finalizeTest: finalizeTest,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleInstituteTest(
    instituteTestId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/reschedule-institute-test`,
        {
          instituteTestId: instituteTestId,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cloneInstituteTest(
    instituteTestId: number,
    testName: string,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/clone-institute-test`,
        { instituteTestId: instituteTestId, testName: testName, affiliationId: affiliationId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishInstituteTest(
    instituteTestId: number,
    affiliationId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/publish-institute-test`,
        {
          instituteTestId: instituteTestId,
          affiliationId: affiliationId,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteInstituteTest(
    instituteTestId: number,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(
        `institute-test/delete-institute-test`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async renameInstituteTest(instituteTestId: number, testName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/rename-institute-test`,
        {
          instituteTestId: instituteTestId,
          testName: testName,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async checkIsParentAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `affiliation-batch/is-parent-affiliation`,
        {
          params: { affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDetailedAffiliationBatches(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<BatchDisplay[]>(
        `affiliation-batch/get-detailed-affiliation-batches`,
        {
          params: { affiliationId: affiliationId, includeChildren: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addNewBatch(updateData: AddEditBatch): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `affiliation-batch/add-new-batch`,
        updateData,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateBatch(batchId: number, updateData: AddEditBatch): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`affiliation-batch/update-batch`, {
        batchId: batchId,
        updateData: updateData,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateBatchStatus(batchId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `affiliation-batch/update-batch-status`,
        { batchId: batchId, isActive: isActive },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAcademicSessions(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `affiliation-batch/get-academic-sessions`,
        { params: { onlyActive: onlyActive } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOwnerAccounts(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`user/get-owner-accounts`, {
        params: { affiliationId: affiliationId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async registerNewUser(userData: AddEditUser): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/register-new-user`, userData);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async registerBulkUsers(userData: AddBulkUsers, csvFile: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<AddBulkUsersResponse>(
        `user/register-bulk-users`,
        { userData: userData, csvFile: csvFile },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateUser(userId: number, updateData: AddEditUser): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-user`, {
        userId: userId,
        updateData: updateData,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateUserStatus(userId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-user-status`, {
        userId: userId,
        isActive: isActive,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateBulkUsersBatch(batchId: number, userIds: Array<number>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-bulk-users-batch`, {
        batchId: batchId,
        userIds: userIds,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationUsersByRoles(
    affiliationId: number,
    roleIdsCsv: string,
    isActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<UserDisplay>>(
        `affiliation-batch/get-affiliation-users-by-roles`,
        {
          params: { affiliationId: affiliationId, roleIdsCsv: roleIdsCsv, isActive: isActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationBatchStudents(batchId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<UserDisplay>>(
        `affiliation-batch/get-batch-students`,
        {
          params: { batchId: batchId, isActive: isActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourses(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`content/get-courses`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseSubjects(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`content/get-course-subjects`, {
        params: { courseId: courseId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getParentAffiliations(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`admin/get-parent-affiliations`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffliationTypes(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`admin/get-affiliation-types`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCities(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`common/get-all-cities`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationsByType(
    affiliationTypeId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `admin/get-affiliations-by-type`,
        {
          params: { affiliationTypeId: affiliationTypeId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationsByName(affiliationName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `admin/get-affiliations-by-name`,
        {
          params: { affiliationName: affiliationName },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationBatches(affiliationId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-affiliation-batches`,
        {
          params: { affiliationId: affiliationId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDetailedAffiliationsByType(
    affiliationTypeId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationDisplay>(
        `admin/get-detailed-affiliations-by-type`,
        {
          params: { affiliationTypeId: affiliationTypeId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDetailedAffiliationsByName(affiliationName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationDisplay>(
        `admin/get-detailed-affiliations-by-name`,
        {
          params: { affiliationName: affiliationName },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addNewAffiliation(affiliationData: AddEditAffiliation): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `admin/add-new-affiliation`,
        affiliationData,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateAffiliation(
    affiliationId: number,
    affiliationData: AddEditAffiliation,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `admin/update-affiliation?affiliationId=${affiliationId}`,
        { affiliationId: affiliationId, updateData: affiliationData },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateAffiliationStatus(affiliationId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`admin/update-affiliation-status`, {
        affiliationId: affiliationId,
        isActive: isActive,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationUserCounts(
    affiliationTypeId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AffiliationUserCounts>>(
        `admin/get-affiliation-user-counts`,
        {
          params: { affiliationTypeId: affiliationTypeId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationActivityCounts(
    affiliationTypeId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AffiliationActivityCounts>>(
        `admin/get-affiliation-activity-counts`,
        {
          params: { affiliationTypeId: affiliationTypeId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachersUsageData(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TeacherUsageCounts>>(
        `admin/get-teacher-usage-counts`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentsUsageData(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<StudentUsageCounts>>(
        `admin/get-student-usage-counts`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionCountsForContent(selectedContent: ContentIds) {
    try {
      const response = await this.axiosInstance.post(`content/get-question-counts-for-content`, {
        selectedContent: selectedContent,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedAssignmentsForAffiliation(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PreloadedAssignmentInfo[]>(
        `assignment/preloaded-assignments-for-affiliation`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedAssignmentsForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(
        `assignment/preloaded-assignments-for-chapter`,
        { params: { courseChapterId: courseChapterId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createAssignment(
    assignmentDesign: AssignmentDesign,
    affiliationId: number,
    courseId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/create-assignment`, {
        assignmentDesign: assignmentDesign,
        affiliationId: affiliationId,
        courseId: courseId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentDesign(assignmentId: number, affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentDesign>(
        `assignment/get-assignment-design`,
        {
          params: { assignmentId: assignmentId, affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishPreloadedAssignment(
    assignmentId: number,
    affiliationId: number,
    assignmentName: string,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
    teachingAssignmentId: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `assignment/publish-preloaded-assignment`,
        {
          assignmentId: assignmentId,
          affiliationId: affiliationId,
          assignmentName: assignmentName,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
          teachingAssignmentId: teachingAssignmentId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveAssignmentQuestions(
    assignmentId: number,
    affiliationId: number,
    sectionGroups: Array<SectionGroup>,
    finalizeAssignment: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `assignment/save-assignment-questions`,
        {
          assignmentId: assignmentId,
          affiliationId: affiliationId,
          sectionGroups: sectionGroups,
          finalizeAssignment: finalizeAssignment,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAvailableAssignmentQuestionsForChapter(
    courseChapterId: number,
    kscClusterIds: Array<number> | null,
    questionTypeId: number,
    onlyActive: boolean,
    exclusionBatchIdsCsv: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<SelectorQuestion[]>(
        `assignment/get-available-questions-for-chapter`,
        {
          courseChapterId: courseChapterId,
          kscClusterIds: kscClusterIds,
          questionTypeId: questionTypeId,
          onlyActive: onlyActive,
          exclusionBatchIdsCsv: exclusionBatchIdsCsv,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentRecommendedQuestions(
    assignmentSection: Section,
    exclusionBatchIds: Array<number> | null,
    difficultyLevelId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<SelectorQuestion[]>(
        `assignment/get-recommended-questions`,
        {
          assignmentSection: assignmentSection,
          exclusionBatchIds: exclusionBatchIds,
          difficultyLevelId: difficultyLevelId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentsForAffliation(affiliationId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentData>(
        `assignment/get-assignments-for-affiliation`,
        {
          params: { affiliationId: affiliationId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentsForStudent(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentStudent>(
        `assignment/get-assignments-for-student`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cloneAssignment(
    assignmentId: number,
    assignmentName: string,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/clone-assignment`, {
        assignmentId: assignmentId,
        assignmentName: assignmentName,
        affiliationId: affiliationId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async mergeAssignments(
    assignmentIds: Array<number>,
    assignmentName: string,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/merge-assignments`, {
        assignmentIds: assignmentIds,
        assignmentName: assignmentName,
        affiliationId: affiliationId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteAssignment(
    assignmentId: number,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(`assignment/delete-assignment`, {
        params: {
          assignmentId: assignmentId,
          affiliationId: affiliationId,
          userId: userId,
          userRole: userRole,
        },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishAssignment(
    assignmentId: number,
    affiliationId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/publish-assignment`, {
        assignmentId: assignmentId,
        affiliationId: affiliationId,
        batchIds: batchIds,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async renameAssignment(assignmentId: number, assignmentName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/rename-assignment`, {
        assignmentId: assignmentId,
        assignmentName: assignmentName,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleAssignment(
    assignmentId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/reschedule-assignment`, {
        assignmentId: assignmentId,
        batchIds: batchIds,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentAssignmentReport(
    assignmentUserId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentReport>(
        `conduct-assignment/student-assignment-report`,
        {
          params: { assignmentUserId: assignmentUserId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentAssignmentReportDetails(
    assignmentUserId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentReportDetails>(
        `conduct-assignment/student-assignment-report-details`,
        {
          params: { assignmentUserId: assignmentUserId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationAssignmentReport(
    assignmentId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationAssignmentReport>(
        `conduct-assignment/affiliation-assignment-report`,
        {
          params: { assignmentId: assignmentId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationAssignmentReportDetails(
    assignmentId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationAssignmentReportDetails>(
        `conduct-assignment/affiliation-assignment-report-details`,
        { params: { assignmentId: assignmentId, userId: userId, userRole: userRole } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loadAssignment(assignmentId: number, userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<ConductAssignmentStore>(
        `conduct-assignment/load-assignment`,
        { params: { assignmentId: assignmentId, userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startAssignment(assignmentUserId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<AssignmentTimeStamps>(
        `conduct-assignment/start-assignment`,
        { assignmentUserId: assignmentUserId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async pushUnsavedAssignmentEvents(
    assignmentUserId: number,
    assignmentEvents: Array<AssignmentEvent>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `conduct-assignment/push-assignment-events`,
        {
          assignmentUserId: assignmentUserId,
          assignmentEvents: assignmentEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endAssignment(
    assignmentInfo: ConductAssignmentInfo,
    assignmentState: ConductAssignmentState,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`conduct-assignment/end-assignment`, {
        AssignmentInfo: assignmentInfo,
        AssignmentState: assignmentState,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUsersBySearchText(affiliationId: number, searchText: string, batchId: number | null) {
    try {
      const response = await this.axiosInstance.get<UserDisplay>(`user/users-by-search-text`, {
        params: { affiliationId: affiliationId, searchText: searchText, batchId: batchId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUsersByLogin(searchText: string) {
    try {
      const response = await this.axiosInstance.get<UserDisplay>(`user/users-by-login`, {
        params: { searchText: searchText },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedAssignmentById(preloadedAssignmentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `assignment/preloaded-assignment-by-id`,
        {
          params: { preloadedAssignmentId: preloadedAssignmentId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestById(preloadedTestId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `institute-test/preloaded-test-by-id`,
        {
          params: { preloadedTestId: preloadedTestId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchWiseSyllabusCoverage(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SyllabusBatchWiseData>>(
        `affiliation-report/batch-wise-syllabus-coverage`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeacherWiseSyllabusCoverage(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SyllabusTeacherWiseData>>(
        `affiliation-report/teacher-wise-syllabus-coverage`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCoursesForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`content/get-courses`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getClassesForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`content/get-classes`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSubjectsForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`content/get-subjects`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTopicsForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`content/get-topics`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getChaptersForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair[]>(`content/get-chapters`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchEffortAnalysis(
    batchId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<BatchEffortAnalysisData>(
        `affiliation-report/batch-wise-effort-analysis`,
        {
          params: { batchId: batchId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseChaptersMapping(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get(`content/get-course-chapters-mapping`, {
        params: { courseId: courseId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchPerformanceReport(batchId: number, subjectId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<BatchPerformanceData>(
        `affiliation-report/batch-performance-report`,
        {
          params: { batchId: batchId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentSubjectCoverageReport(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SubjectCoverageData>(
        `student-report/subject-coverage-report`,
        {
          params: { userId: userId, courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentSubjectTrends(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PerformanceTrendsData>(
        `student-report/subject-trends`,
        {
          params: { userId: userId, courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSubjectsForStudent(userId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair>(
        `content/get-subjects-for-student`,
        {
          params: { userId: userId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateQuestionSolution(questionDetails: QuestionDetailsUpdate): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/update-question-solution`, {
        questionDetails: questionDetails,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateQuestionHtml(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/generate-question-html`, {
        questionId: questionId,
        runInContainer: true,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async uploadNewQuestionsPdf(
    pdfFileName: string,
    pdfContent: string,
    pdfSettings: QuestionPdfSettings,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/upload-new-questions-pdf`, {
        pdfFileName: pdfFileName,
        pdfContent: pdfContent,
        pdfSettings: pdfSettings,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionsPdfInfo(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<QuestionPdfInfo>(
        `content/get-questions-pdf-info`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQuestionsPdfInfo(questionPdfInfo: QuestionPdfInfo): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/save-questions-pdf-info`, {
        questionPdfInfo: questionPdfInfo,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async verifyNewQuestionsPdf(questionPdfInfo: QuestionPdfInfo): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/verify-new-questions-pdf`, {
        questionPdfInfo: questionPdfInfo,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionsPdfImages(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionSolutionImage>>(
        `content/get-questions-pdf-images`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPdfPagesForPdfUUID(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionPdfPage>>(
        `content/get-pdf-pages-for-pdf-uuid`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createNewPdfUUID(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(`content/create-new-pdf-uuid`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQuestionSolutionImages(
    pdfUUID: string,
    questionSolutionImages: Array<QuestionSolutionImage>,
    saveQuestionBank: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(
        `content/save-question-solution-images`,
        {
          pdfUUID: pdfUUID,
          questionSolutionImages: questionSolutionImages,
          saveQuestionBank: saveQuestionBank,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionsFromQuestionBank(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionBankQuestion>>(
        `content/get-questions-from-question-bank`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQuestionBankTags(questionTags: Array<QuestionBankQuestion>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(`content/save-question-bank-tags`, {
        questionTags: questionTags,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async processQuestionBankQuestions(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `content/process-question-bank-questions`,
        { pdfUUID: pdfUUID },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}

import { UserAlert } from "@/types/global";

export const CompanyFullname = "Teevra Edutech Pvt Ltd";

export const GooglePlayStoreUrl =
  "https://play.google.com/store/apps/details?id=in.speedlabs.science&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";

export const AppleAppStoreUrl =
  "https://apps.apple.com/in/app/speedlabs/id1606108997?itsct=apps_box_badge&itscg=30200";

export const FacebookUrl = "https://www.facebook.com/speedlabsindia/";
export const LinkedInUrl = "https://www.linkedin.com/company/speedlabs/mycompany/";
export const TwitterUrl = "https://twitter.com/SpeedLabs_India";
export const InstagramUrl = "https://www.instagram.com/speedlabs.india/";
export const YoutubeUrl = "https://www.youtube.com/@SpeedLabs";

export const YoutubeBaseUrl = "https://www.youtube.com/embed/";
export const YoutubeQueryParams =
  "amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1";
export const YoutubeUrlRegex =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const DifficultyColor: { [DifficultyLevel: string]: string } = Object.freeze({
  Easy: "success",
  Medium: "warning",
  Hard: "danger",
});

export const MultiSubjectName = "Multi-Subject";
export const DefaultSubjectIcon = "default";
export const AddBulkUsersCsvTemplateUrl = "~/webapp/AddBulkUsersTemplate.csv";

export const appRefreshAlert = {
  messageTitle: "CRITICAL",
  messageDescription:
    "It seems that you may have an older version of the SpeEdLabs app. We recommend refreshing the page (Ctrl + F5) to update your app.",
  messageType: "error",
  isDark: true,
} as UserAlert;

export const VueMathJaxOptions = {
  extensions: ["tex2jax.js"],
  showProcessingMessages: false,
  jax: ["input/TeX", "output/CommonHTML"],
  showMathMenu: false,
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
    processEscapes: false,
    processClass: "tex2jax_process",
  },
  CommonHTML: {
    scale: 85,
    mtextFontInherit: true,
    linebreaks: { automatic: true },
  },
};

export const MaxAffiliationActivityDays = 365;
export const PixelErrorThreshold = 10;

import { YoutubeBaseUrl, YoutubeQueryParams, YoutubeUrlRegex } from "@/constants/global";
import router from "@/router";
import { useSessionStore } from "@/stores/sessionStore";
import { CustomMessage, IdNameChildren, IdNamePair } from "@/types/global";
import axios from "axios";
import { format, intervalToDuration, parseISO } from "date-fns";
import { ElMessage, UploadUserFile } from "element-plus";

declare global {
  interface Window {
    flutter_inappwebview: any;
  }
}

export async function onWebAppLogout(isSystemExpired: boolean) {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("gotoWebviewLogoutId", "gotoWebviewLogout");
  } else {
    router.push({ name: "Logout", params: { isSystemExpired: String(isSystemExpired) } });
  }
}

export function gotoDashboard() {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("gotoDashboardButtonId", "gotoDashboardButton");
  } else {
    const sessionStore = useSessionStore();
    router.push({ name: sessionStore.UserInfo.UserDashboard });
  }
}

export function gotoPrevious() {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("gotoPreviousButtonId", "gotoPreviousButton");
  } else {
    router.back();
  }
}

export function gotoRouteWithFlutterEvent(routeName: string) {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(`goto${routeName}Id`, `goto${routeName}`);
  } else {
    router.push({ name: routeName });
  }
}

export function getAzureUrl(partialUrl: string): string {
  const baseUrl = import.meta.env.VITE_AZURE_BASE_URL;
  const sessionStore = useSessionStore();

  const fullUrl = partialUrl.startsWith("~")
    ? partialUrl.replace("~", baseUrl)
    : `${baseUrl}/${partialUrl}`;
  return `${fullUrl}?session=${sessionStore.UserInfo.SessionUuid}`;
}

export function getImageUrl(imageName: string | undefined | null): string | undefined {
  if (imageName === undefined || imageName === null) {
    return undefined;
  }
  return getAzureUrl(`webapp/assets/images/${imageName}`);
}

export function getImageSrc(imageUrl: string | undefined | null): string | undefined {
  if (imageUrl === undefined || imageUrl === null) {
    return undefined;
  }
  return imageUrl.startsWith("data:") ? imageUrl : getAzureUrl(imageUrl);
}

export function getIconUrl(iconName: string | undefined | null): string | undefined {
  if (iconName === undefined || iconName === null) {
    return undefined;
  }
  return getAzureUrl(`webapp/assets/icons/${iconName}.svg`);
}

export function getSubjectIconUrl(subjectName: string, isColor: boolean): string {
  const iconName = subjectName.replaceAll(" ", "").toLowerCase().toString();
  const iconType = isColor ? "color" : "wireframe";
  return getAzureUrl(`webapp/assets/icons/subjects/${iconType}/${iconName}.svg`);
}

export function getNavbarIconUrl(iconName: string | undefined | null): string | undefined {
  if (iconName === undefined || iconName === null) {
    return undefined;
  }
  return getAzureUrl(`webapp/assets/icons/navbar/${iconName}.svg`);
}

export function getAlphabetIconUrl(userName: string, isUppercase: boolean): string {
  let iconName = null;
  iconName = userName.replaceAll(" ", "").toLowerCase().toString();
  iconName = iconName.length > 0 ? iconName[0] : null;
  if (iconName === null || !/[a-zA-Z]/g.test(iconName)) {
    iconName = "x";
  }
  const iconType = isUppercase ? "uppercase" : "lowercase";
  return getAzureUrl(`webapp/assets/icons/alphabets/${iconType}/${iconName}.svg`);
}

export function getVideoEmbedUrl(videoUrl: string): string | undefined {
  const regexMatch = videoUrl.match(YoutubeUrlRegex);
  const youtubeId = regexMatch && regexMatch[7].length === 11 ? regexMatch[7] : undefined;
  return youtubeId ? `${YoutubeBaseUrl}${youtubeId}?${YoutubeQueryParams}` : videoUrl;
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function copyToClipboard(textToCopy: string | null, textDescription: string) {
  if (textToCopy === null) {
    return;
  }
  navigator.clipboard.writeText(textToCopy);
  ElMessage({ message: `${textDescription} copied to clipboard!`, type: "success" });
}

export async function pasteImageFromClipboard() {
  const clipboardItems = await navigator.clipboard.read();
  for (let i = 0, len = clipboardItems.length; i < len; i++) {
    if (clipboardItems[i].types.includes("image/png")) {
      const clipboardData = await clipboardItems[i].getType("image/png");
      const encodedFile = await convertBlobToBase64(clipboardData);
      return encodedFile;
    }
  }
}

export function convertMinutesToDuration(
  timeInMinutes: number | undefined,
  minimumThreshold: number = 1,
  shortVersion: boolean = false,
): string | undefined {
  if (timeInMinutes === undefined || timeInMinutes <= minimumThreshold) {
    return;
  }
  return convertSecondsToDuration(timeInMinutes * 60, minimumThreshold * 60, shortVersion);
}

export function convertSecondsToDuration(
  timeInSeconds: number | undefined,
  minimumThreshold: number = 60,
  shortVersion: boolean = false,
): string | undefined {
  if (timeInSeconds === undefined || timeInSeconds <= minimumThreshold) {
    return;
  }
  const roundedTimeInSecs = minimumThreshold * Math.floor(timeInSeconds / minimumThreshold);
  interface durationDictionary {
    [key: string]: number;
  }
  const duration = intervalToDuration({
    start: 0,
    end: roundedTimeInSecs * 1000,
  }) as durationDictionary;
  Object.keys(duration).forEach((key) => {
    if (duration[key] === 0) {
      delete duration[key];
    }
  });
  const formttedDuration = Object.keys(duration)
    .map((key) => {
      const timeName = shortVersion ? key[0] : ` ${duration[key] === 1 ? key.slice(0, -1) : key}`;
      return `${duration[key]}${timeName}`;
    })
    .join(" ");
  return formttedDuration;
}

export function convertToTreeData(
  data: Array<object>,
  treeLevels: Array<string>,
): Array<IdNameChildren> {
  const idNameChildren: Array<IdNameChildren> = [];
  for (let i = 0; i < data.length; i++) {
    let children = undefined;
    const currentItem = data[i];
    const currentIdName = data[i] as IdNamePair;
    const typedContentType = treeLevels[0] as keyof typeof currentItem;
    if (treeLevels.length > 0 && data[i][typedContentType] !== undefined) {
      children = convertToTreeData(data[i][typedContentType], treeLevels.slice(1));
      idNameChildren.push({ Id: currentIdName.Id, Name: currentIdName.Name, Children: children });
    } else {
      idNameChildren.push({ Id: currentIdName.Id, Name: currentIdName.Name });
    }
  }
  return idNameChildren;
}

function getCleanEnumName(enumKey: string) {
  if (enumKey === undefined) {
    return "";
  }
  return toTitleCase(enumKey.replaceAll("_", " "));
}

export function getIdNamePairsFromEnum(optionsEnum: any): IdNamePair[] {
  const idNamePairs: IdNamePair[] = [];
  const enumKeys = Object.keys(optionsEnum).filter((v) => isNaN(Number(v)));
  enumKeys.forEach((key) => {
    const cleanName = getCleanEnumName(key);
    idNamePairs.push({ Id: optionsEnum[key], Name: cleanName });
  });
  return idNamePairs;
}

export function getNameFromEnumId(id: number | string, optionsEnum: any): string {
  return getCleanEnumName(optionsEnum[id]);
}

export function toTitleCase(str: string): string {
  return str.toLowerCase().replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
}

export function splitStringByUpperCase(str: string): string {
  const strParts = str.match(/[A-Z][a-z]+/g);
  return strParts ? strParts.join(" ") : str;
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getFormattedDate(datetimeString: string) {
  return format(parseISO(datetimeString), "dd MMM yyyy");
}

export function getFormattedDateTime(datetimeString: string) {
  return format(parseISO(datetimeString), "dd MMM h:mmaaaaa'm'");
}

export function getFormattedTime(datetimeString: string) {
  return format(parseISO(datetimeString), "hh:mm");
}

export function getDateTimeString(datetime: Date) {
  return format(datetime, "yyyy-MM-dd HH:mm");
}

export function getFormattedPercentage(percentValue: number, precision: number) {
  return Number(percentValue).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: precision,
  });
}

export function getNMoreString(stringValues: string | string[] | undefined) {
  if (!stringValues) {
    return "";
  } else if (Array.isArray(stringValues)) {
    if (stringValues.length > 1) {
      return `${stringValues[0]} +${stringValues.length - 1} more`;
    } else {
      return stringValues[0];
    }
  } else {
    return stringValues;
  }
}

export function getDateRangeDisplay(dateRange: Array<string>) {
  if (!dateRange) {
    return null;
  }
  let startDate = getFormattedDate(dateRange[0]);
  let endDate = getFormattedDate(dateRange[1]);
  if (startDate === endDate) {
    return startDate;
  }
  const commonSubstring = commonTrailingSubstring(startDate, endDate);
  const commonSpaceIndex = commonSubstring.indexOf(" ");
  const cutoffSubstring = commonSubstring.substring(commonSpaceIndex).trim();
  startDate = startDate.replace(cutoffSubstring, "").trim();
  endDate = endDate.replace(cutoffSubstring, "").trim();
  return `${startDate} - ${endDate} ${cutoffSubstring}`;
}

export function commonTrailingSubstring(string1: string, string2: string) {
  let i = string1.length;
  let j = string2.length;
  while (i >= 0 && j >= 0 && string1.charAt(i) === string2.charAt(j)) {
    i--;
    j--;
  }
  return string1.substring(i + 1);
}

export function getContentArray(contentValue: number | Array<number> | null) {
  return Array.isArray(contentValue) ? contentValue : contentValue === null ? [] : [contentValue];
}

export function showCustomMessage(messageDetails: CustomMessage) {
  if (messageDetails.messageText === undefined) {
    return;
  }
  if (
    messageDetails.duration !== undefined &&
    (messageDetails.duration === 0 || messageDetails.duration > 10)
  ) {
    messageDetails.showClose = true;
  }
  return ElMessage({
    //@ts-expect-error Typing error from element-plus
    message: messageDetails.messageText,
    type: messageDetails.messageType ? messageDetails.messageType : "info",
    duration: (messageDetails.duration !== undefined ? messageDetails.duration : 5) * 1000,
    grouping: messageDetails.enableGrouping ? messageDetails.enableGrouping : true,
    showClose: messageDetails.showClose ? messageDetails.showClose : false,
    offset: 20,
    customClass: "custom-message",
  });
}

export function downloadCsvData(
  objectData: Array<any>,
  objectKeys: Array<string> | null,
  fileName: string,
) {
  const flattenedData = objectData.map((o) => flattenObject(o));
  let objectHeaders = Object.keys(flattenedData[0]);
  if (objectKeys !== null) {
    objectHeaders = objectKeys.filter((ok) => objectHeaders.includes(ok));
  }
  const objectRows = flattenedData.map((o) => objectHeaders.map((h) => o[h]));
  const escapedRows = objectRows.map((row) => {
    const newRow = row.map((col) => {
      return col === undefined || col === null ? "" : `"${col}"`;
    });
    return newRow;
  });
  const headerRow = objectHeaders.join(",");
  const csvRows = [headerRow, ...escapedRows.map((row) => row.join(","))];
  const csvData = csvRows.join("\n");
  fileName = `${fileName}.csv`;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(`downloadCsvButtonId`, {
      csvData: csvData,
      fileName: fileName,
    });
  } else {
    downloadFile(csvData, "text/csv;charset=utf-8", fileName);
  }
}

export async function downloadCsvFile(
  csvUrl: string,
  fileName: string,
  randomize: boolean = false,
) {
  await downloadFileFromUrl(csvUrl, "text/csv", fileName, randomize);
}

export async function downloadPdf(pdfUrl: string, fileName: string, randomize: boolean = false) {
  await downloadFileFromUrl(pdfUrl, "application/pdf", fileName, randomize);
}

export async function downloadFileFromUrl(
  fileUrl: string,
  fileType: string,
  fileName: string,
  randomize: boolean = false,
) {
  if (fileUrl.startsWith("~")) {
    fileUrl = getAzureUrl(fileUrl);
  }
  if (randomize) {
    const connector = fileUrl.includes("?") ? "&" : "?";
    fileUrl = `${fileUrl}${connector}random=${Math.random().toString()}`;
  }
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(`downloadFileButtonId`, {
      url: fileUrl,
      fileType: fileType,
      fileName: fileName,
    });
  } else {
    axios({ url: fileUrl, method: "get", responseType: "arraybuffer" })
      .then((response) => {
        downloadFile(response.data, fileType, fileName);
      })
      .catch(() => {
        showCustomMessage({
          messageText: "Oops! There was an error downloading the file.",
          messageType: "error",
        });
      });
  }
}

function downloadFile(fileData: BlobPart, fileType: string, fileName: string) {
  const fileBlob = new Blob([fileData], { type: fileType });
  const downloadLink = document.createElement("a");
  const downloadHref = URL.createObjectURL(fileBlob);
  downloadLink.href = downloadHref;
  downloadLink.setAttribute("download", fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadHref);
}

function flattenObject(nestedObject: any, prefix = "") {
  const flattenedObject: any = {};
  Object.keys(nestedObject).forEach((key) => {
    if (typeof nestedObject[key] === "object" && nestedObject[key] !== null) {
      Object.assign(flattenedObject, flattenObject(nestedObject[key], key));
    } else {
      const flatKey = prefix ? `${prefix}.${key}` : key;
      flattenedObject[flatKey] = nestedObject[key];
    }
  });
  return flattenedObject;
}

export async function getEncodedUploadFile(uploadFile: Array<UploadUserFile> | undefined) {
  if (!uploadFile || uploadFile.length === 0) {
    return;
  }
  const allEncodedFiles = await getEncodedUploadFiles([uploadFile[0]]);
  return allEncodedFiles ? allEncodedFiles[0] : undefined;
}

export async function getEncodedUploadFiles(uploadFiles: Array<UploadUserFile>) {
  if (!uploadFiles || uploadFiles.length === 0) {
    return;
  }
  const allEncodedFiles: Array<string> = [];
  for (let i = 0; i < uploadFiles.length; i++) {
    const fileContent = uploadFiles[i].raw;
    if (fileContent) {
      try {
        const encodedFile = await convertBlobToBase64(fileContent);
        if (encodedFile) {
          allEncodedFiles.push(encodedFile);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  return allEncodedFiles;
}

export async function convertBlobToBase64(fileContent: Blob): Promise<string | undefined> {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.readAsDataURL(fileContent);
    fileReader.onload = async () => {
      resolve(fileReader.result ? fileReader.result.toString() : undefined);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function deepCopyObject(obj: object): object {
  return JSON.parse(JSON.stringify(obj));
}

export function generateRandomString(stringLength: number) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < stringLength; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
